import { createSlice } from '@reduxjs/toolkit';
import {
  WhereFrom,
  WhereUsed,
  Batch,
  Items,
} from './types';
import { batchesApi } from '../api/batchesApi';

type BatchStatus = 'idle' | 'pending' | 'succeeded' | 'failed'

type BatchesSliceState = {
    items: Items<Batch>,
    status: BatchStatus
    autocompleteStatus: BatchStatus
    autocomplete: Array<Batch['batchId']>
    errorMessage: string
    genealogyData: Items<WhereFrom.BatchGenealogy | WhereUsed.BatchGenealogy>
    genealogyStatus: BatchStatus
    selectedBatchForGenealogy: Batch | null
}

const defaultItems = { body: [], page: 1, totalRowsCount: 0 };

const initialState: BatchesSliceState = {
  items: defaultItems,
  autocomplete: [],
  autocompleteStatus: 'idle',
  status: 'idle',
  errorMessage: '',
  selectedBatchForGenealogy: null,
  genealogyData: defaultItems,
  genealogyStatus: 'idle',
};

const ERROR_MESSAGES = {
  401: 'User is not authorized',
  404: 'The batch you searched on not found. Please verify the batch number or try wildcard searching. '
      + 'Remember the batch data source is refreshed every 24 hours.',
  400: 'The batch you searched on not found. Please verify the batch number or try wildcard searching. '
      + 'Remember the batch data source is refreshed every 24 hours.',
  FETCH_ERROR: 'System connectivity error',
};

export const batchesSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    clearItems: (state) => {
      state.items = defaultItems;
    },
    clearAutocomplete: (state) => {
      state.autocomplete = [];
    },
    setSelectedBatch: (state, { payload }) => {
      state.selectedBatchForGenealogy = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      batchesApi.endpoints.batches.matchFulfilled,
      (state, { payload }) => {
        state.items = payload;
        state.status = 'succeeded';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batches.matchPending,
      (state) => {
        state.status = 'pending';
        state.errorMessage = '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batches.matchRejected,
      (state, { payload }) => {
        state.status = 'failed';
        state.items = defaultItems;
        // @ts-ignore
        state.errorMessage = ERROR_MESSAGES[payload.originalStatus || payload.status] || payload?.data || payload?.error || '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereFrom.matchFulfilled,
      (state, { payload }) => {
        // @ts-ignore
        state.items = payload;
        state.status = 'succeeded';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereFrom.matchPending,
      (state) => {
        state.status = 'pending';
        state.errorMessage = '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereFrom.matchRejected,
      (state, { payload }) => {
        state.status = 'failed';
        state.items = defaultItems;
        // @ts-ignore
        state.errorMessage = ERROR_MESSAGES[payload.originalStatus || payload.status] || payload?.data || payload?.error || '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereUsed.matchFulfilled,
      (state, { payload }) => {
        // @ts-ignore
        state.items = payload;
        state.status = 'succeeded';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereUsed.matchPending,
      (state) => {
        state.status = 'pending';
        state.errorMessage = '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereFromGenealogy.matchRejected,
      (state, { payload }) => {
        state.genealogyStatus = 'failed';
        // @ts-ignore
        state.genealogyData = [];
        // @ts-ignore
        state.errorMessage = ERROR_MESSAGES[payload.originalStatus || payload.status] || payload?.data || payload?.error || '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereFromGenealogy.matchFulfilled,
      (state, { payload }) => {
        // @ts-ignore
        state.genealogyData = payload;
        state.genealogyStatus = 'succeeded';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereFromGenealogy.matchPending,
      (state) => {
        state.genealogyStatus = 'pending';
        state.errorMessage = '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereUsedGenealogy.matchRejected,
      (state, { payload }) => {
        state.genealogyStatus = 'failed';
        // @ts-ignore
        state.genealogyData = [];
        // @ts-ignore
        state.errorMessage = ERROR_MESSAGES[payload.originalStatus || payload.status] || payload?.data || payload?.error || '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereUsedGenealogy.matchFulfilled,
      (state, { payload }) => {
        // @ts-ignore
        state.genealogyData = payload;
        state.genealogyStatus = 'succeeded';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereUsedGenealogy.matchPending,
      (state) => {
        state.genealogyStatus = 'pending';
        state.errorMessage = '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.batchesWhereUsed.matchRejected,
      (state, { payload }) => {
        state.status = 'failed';
        state.items = defaultItems;
        // @ts-ignore
        state.errorMessage = ERROR_MESSAGES[payload.originalStatus || payload.status] || payload?.data || payload?.error || '';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.autocomplete.matchFulfilled,
      (state, { payload }) => {
        state.autocomplete = payload;
        state.autocompleteStatus = 'succeeded';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.autocomplete.matchRejected,
      (state) => {
        state.autocompleteStatus = 'succeeded';
      }
      ,
    );
    builder.addMatcher(
      batchesApi.endpoints.autocomplete.matchPending,
      (state) => {
        state.autocompleteStatus = 'pending';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.autocompleteWhereFrom.matchFulfilled,
      (state, { payload }) => {
        state.autocomplete = payload;
        state.autocompleteStatus = 'succeeded';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.autocompleteWhereFrom.matchPending,
      (state) => {
        state.autocompleteStatus = 'pending';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.autocompleteWhereUsed.matchFulfilled,
      (state, { payload }) => {
        state.autocomplete = payload;
        state.autocompleteStatus = 'succeeded';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.autocompleteWhereUsed.matchPending,
      (state) => {
        state.autocompleteStatus = 'pending';
      },
    );
    builder.addMatcher(
      batchesApi.endpoints.autocompleteWhereUsed.matchRejected,
      (state) => {
        state.autocompleteStatus = 'succeeded';
      }
      ,
    );
    builder.addMatcher(
      batchesApi.endpoints.autocompleteWhereFrom.matchRejected,
      (state) => {
        state.autocompleteStatus = 'succeeded';
      },
    );
  },
});

export const getBatches = (state: RootState) => state.batches.items;
export const getStatus = (state: RootState) => state.batches.status;
export const getErrorMessage = (state: RootState) => state.batches.errorMessage;
export const getAutocompleteState = (state: RootState) => state.batches.autocomplete;
export const getAutocompleteStatus = (state: RootState) => state.batches.autocompleteStatus;
export const getGenealogyStatus = (state: RootState) => state.batches.genealogyStatus;
export const getGenealogyData = (state: RootState) => state.batches.genealogyData;
export const getSelectedBatch = (state: RootState) => state.batches.selectedBatchForGenealogy;

export const { clearItems, clearAutocomplete, setSelectedBatch } = batchesSlice.actions;
