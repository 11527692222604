import {
  Batch,
  Items,
  WhereFrom,
  WhereUsed,
} from '../model/types';
import {
  mapDtoToBatch,
  mapWhereFromToBatch,
  mapWhereUsedToBatch,
} from '../model/utils';
import { baseAPI, veevaAPI } from '../../../shared/api/baseAPI';
import { VeevaResponse } from '../../cart/model/types';
import { ENDPOINTS, getUrl } from '../../../shared/config/endpoints';
import { joinQP } from '../../../shared/utils/joinQP';

export const batchesApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    batches: builder.query<Items<Batch>,
        { batchId: string, page: number, limit: number }>({
          query: ({ batchId, page, limit }) => ({
            url: `${getUrl(ENDPOINTS.GET_BATCHES, { num: batchId })}?page=${page}&limit=${limit}`,
          }),
          transformResponse: mapDtoToBatch,
          forceRefetch(): boolean {
            return true;
          },
        }),
    batchesWhereFrom: builder
      .query<Items<WhereFrom.BatchGenealogy>, {batchId: string, page: number, limit: number}>({
        query: ({ batchId, page, limit }) => ({
          url: `${getUrl(ENDPOINTS.GET_BATCHES_WHERE_FROM, { num: batchId })}?page=${page}&limit=${limit}`,
        }),
        transformResponse: mapWhereFromToBatch,
        forceRefetch(): boolean {
          return true;
        },
      }),
    batchesWhereFromGenealogy: builder
      .query<Items<WhereFrom.BatchGenealogy>,
      {
          batchId: string,
          material: string,
          plant: string,
          page: number,
          limit?: number,
          params: Array<{ key: string, value: string | number }>
      }>({
        query: ({
          batchId, material, plant, page, limit, params,
        }) => ({
          url: `${getUrl(ENDPOINTS.GET_GENEALOGY_WHERE_FROM, { num: batchId })}?material=${material}&plant=${plant}&page=${page}&limit=${limit}&${joinQP(params)}`,
        }),
        transformResponse: mapWhereFromToBatch,
        forceRefetch(): boolean {
          return true;
        },
      }),
    batchesWhereUsed: builder
      .query<Items<WhereUsed.BatchGenealogy>, {batchId: string, page: number, limit: number}>({
        query: ({ batchId, page, limit }) => ({
          url: `${getUrl(ENDPOINTS.GET_BATCHES_WHERE_USED, { num: batchId })}?page=${page}&limit=${limit}`,
        }),
        transformResponse: mapWhereUsedToBatch,
        forceRefetch(): boolean {
          return true;
        },
      }),
    batchesWhereUsedGenealogy: builder
      .query<Items<WhereUsed.BatchGenealogy>,
      {
          batchId: string, material: string, plant: string, page: number, limit?: number,
          params: Array<{ key: string, value: string | number }>
      }>({
        query: ({
          batchId, material, plant, page, limit, params,
        }) => ({
          url: `${getUrl(ENDPOINTS.GET_GENEALOGY_WHERE_USED, { num: batchId })}?material=${material}&plant=${plant}&page=${page}&limit=${limit}&${joinQP(params)}`,
        }),
        transformResponse: mapWhereUsedToBatch,
        forceRefetch(): boolean {
          return true;
        },
      }),
    autocomplete: builder.query<Array<Batch['batchId']>, string>({
      query: (sub) => ({
        url: getUrl(ENDPOINTS.GET_AUTOCOMPLETE_BATCH, { num: sub }),
      }),
      forceRefetch(): boolean {
        return true;
      },
    }),
    autocompleteWhereUsed: builder.query<Array<Batch['batchId']>, string>({
      query: (sub) => ({
        url: getUrl(ENDPOINTS.GET_AUTOCOMPLETE_WHERE_USED, { num: sub }),
      }),
      forceRefetch(): boolean {
        return true;
      },
    }),
    autocompleteWhereFrom: builder.query<Array<Batch['batchId']>, string>({
      query: (sub) => ({
        url: getUrl(ENDPOINTS.GET_AUTOCOMPLETE_WHERE_FROM, { num: sub }),
      }),
      forceRefetch(): boolean {
        return true;
      },
    }),
    whereFromFilters: builder.query<
        { body: Array<string> },
        {
            batchId: string,
            filterName: string,
            flow: string,
            params: Array<{key: string, value: string | number}>
        }>({
          query: ({
            filterName, batchId, params = [], flow,
          }) => ({
            url: `${getUrl(
              ENDPOINTS.GET_GENEALOGY_FILTERS,
              { filter: filterName, num: batchId, flow },
            )}?${joinQP(params)}`,
          }),
          forceRefetch(): boolean {
            return true;
          },
        }),
  }),
});

type BatchDTO = {
    batchID: string
    countryName?: string
    externalBatchID?: string
    objectTypeName?: string
    materialID: string
    plant: string
    quantityV?: string
    protocolName?: string
}

type GenealogyBatchDTO = {
    id: string,
    level: string,
    plant: string,
    materialBatchType: string,
    supplyChainStage: string,
    parentBatch: string,
    dateOfExpiry: string,
    materialId: string,
    purchaseOrder: string | null,
    vendorLotNumber: string | null,
}

type VeevaObjectDTO<T extends object = GenealogyBatchDTO> = {
    recordNumber: string
    batches: Array<T>
}

export const mapBatchToVeevaObjectDTO = (
  batches: Batch[],
  state: RootState,
): VeevaObjectDTO<BatchDTO> => ({
  recordNumber: state.config.recordNum!,
  batches: batches
    .map((batch) => ({
      batchID: batch.batchId,
      materialID: String(batch.materialId),
      plant: String(batch.plantCode),
    })),
});

export const mapBatchToVeevaObjectWhereFromDTO = (
  batches: WhereFrom.BatchGenealogy[],
  state: RootState,
): VeevaObjectDTO =>
  ({
    recordNumber: state.config.recordNum!,
    batches: batches.map((el) => ({
      id: String(el.batchId),
      plant: String(el.plantCode),
      dateOfExpiry: String(el.expiryDate).split('T')[0],
      level: String(el.batchLevel),
      materialBatchType: el.materialType,
      parentBatch: el.parentBatch,
      materialId: String(el.materialId),
      purchaseOrder: el.purchaseOrderNumber || '',
      supplyChainStage: el.supplyChainStageCode,
      dateOfManufacture: String(el.dateOfManufacture).split('T')[0],
      vendorLotNumber: el.vendorLotNumber,
    })),
  });

export const mapBatchToVeevaObjectWhereUsedDTO = (
  batches: WhereUsed.BatchGenealogy[],
  state: RootState,
): VeevaObjectDTO => ({
  recordNumber: state.config.recordNum!,
  batches: batches.map((el) => ({
    id: String(el.batchId),
    plant: String(el.plantCode),
    dateOfExpiry: String(el.expiryDate).split('T')[0],
    level: String(el.batchLevel),
    materialBatchType: el.materialType,
    parentBatch: el.parentBatch,
    materialId: String(el.materialId),
    purchaseOrder: el.purchaseOrderNumber || '',
    supplyChainStage: el.supplyChainStageCode,
    dateOfManufacture: String(el.dateOfManufacture).split('T')[0],
    vendorLotNumber: el.vendorLotNumber,
  })),
});

export const veevaObjectsApi = veevaAPI.injectEndpoints({
  endpoints: (builder) => ({
    saveVeevaObject: builder.mutation<VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
      query: ({ batch }) => ({
        url: getUrl(ENDPOINTS.SAVE_BATCH, {}),
        method: 'POST',
        body: batch,
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
        },
      }),
    }),
    saveVeevaObjectGenealogyWhereFrom: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
          query: ({ batch }) => ({
            url: getUrl(ENDPOINTS.SAVE_BATCH_WHERE_FROM, {}),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),
    saveVeevaObjectGenealogyWhereUsed: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
          query: ({ batch }) => ({
            url: getUrl(ENDPOINTS.SAVE_BATCH_WHERE_USED, {}),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),
  }),
});

export const {
  useSaveVeevaObjectMutation,
  useSaveVeevaObjectGenealogyWhereUsedMutation,
  useSaveVeevaObjectGenealogyWhereFromMutation,
} = veevaObjectsApi;
export const { useBatchesQuery: useBatchesListQuery, useWhereFromFiltersQuery } = batchesApi;
