import {
  BatchDTO, DateType, Items, SafetyBatch, WhereFrom, WhereUsed,
} from './types';

const removeLeadingZeros = (str: string): string => str.replace(/^0+/, '');

export const mapDtoToBatch = (data: Items<BatchDTO>): Items<SafetyBatch> => ({
  page: data.page,
  limit: data.limit,
  totalRowsCount: data.totalRowsCount,
  body: data.body
    .map((el) =>
      ({
        rootBatch: el.rootBatch,
        rootMaterial: el.rootMaterial,
        rootPlant: el.rootPlant,
        expiryDate: (el?.expiryDate || '') as DateType,
        dateOfManufacture: (el.dateOfManufactory || '') as DateType,
        materialDescription: el.materialDescription || '',
        materialId: el.materialId,
        id: el.id,
        batchId: String(el.batchId),
        vendorLotNumber: el.vendorLotNumber,
        plantCode: Number(el.plant),
      })),
});

export const mapWhereFromToBatch = (data: Items<BatchDTO>): Items<WhereFrom.BatchGenealogy> => ({
  page: data.page,
  limit: data.limit,
  totalRowsCount: data.totalRowsCount,
  body: data.body
    .map((el) => ({
      rootBatch: el.rootBatch,
      rootMaterial: el.rootMaterial,
      rootPlant: el.rootPlant,
      plant: el.plant,
      batchId: String(el?.batchId ?? ''),
      batchLevel: Number(el.level),
      id: el.id,
      vendorLotNumber: el.vendorLotNumber,
      expiryDate: (el?.dateOfExpiry || el?.expiryDate || '') as DateType,
      dateOfManufacture: (el.dateOfManufacture || el.dateOfManufactory || '') as DateType,
      materialDescription: el.materialDescription ?? '',
      materialId: removeLeadingZeros(el.materialId),
      cycleFlag: el.cycle,
      parentBatch: el.parentBatch,
      materialType: el?.materialType ?? '',
      parentMaterial: el?.parentMaterial ?? '',
      parentPlant: String(el?.parentPlant ?? ''),
      plantCode: Number(el.plant),
      purchaseOrderNumber: el.purchaseOrder,
      supplyChainStageCode: el.supplyChainStage,
      typeOfExclusion: el.exclusionType,
      multipleTreeIndex: el?.multipleTreeIndex,
      excludedMaterialFlag: el.excluded,
    })),
});

export const mapWhereUsedToBatch = (data: Items<BatchDTO>): Items<WhereUsed.BatchGenealogy> => ({
  page: data.page,
  limit: data.limit,
  totalRowsCount: data.totalRowsCount,
  body: data.body
    .map((el) => ({
      rootBatch: el.rootBatch,
      rootMaterial: el.rootMaterial,
      rootPlant: el.rootPlant,
      plant: el.plant,
      batchId: String(el?.batchId ?? ''),
      batchLevel: Number(el.level),
      id: el.id,
      vendorLotNumber: el.vendorLotNumber,
      expiryDate: (el?.dateOfExpiry || el?.expiryDate || '') as DateType,
      dateOfManufacture: (el.dateOfManufacture || el.dateOfManufactory || '') as DateType,
      materialDescription: el.materialDescription ?? '',
      materialId: removeLeadingZeros(el.materialId),
      cycleFlag: el.cycle,
      parentBatch: el.parentBatch,
      materialType: el?.materialType ?? '',
      parentMaterial: el?.parentMaterial ?? '',
      parentPlant: String(el?.parentPlant ?? ''),
      plantCode: Number(el.plant),
      purchaseOrderNumber: el.purchaseOrder,
      supplyChainStageCode: el.supplyChainStage,
      redundantFlag: el.redundant,
      multipleTreeIndex: el?.multipleTreeIndex,
    })),
});
