import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setProperty } from '../../entyties/config/model/Config';
import { readQueryParameters } from '../../shared/utils/readQueryParameters';
import { setVeevaToken } from '../../entyties/veevaSession/model/VeevaSession';

export default function withAuth(component: () => React.ReactNode) {
  return function _() {
    const {
      token,
      key: keyValue,
      ccRecordNum,
      PVSaRecordNum,
      COVEWhereUsed,
      COVEWhereFrom,
      recordNum,
      recordName,
      userName,
      userID,
    } = readQueryParameters();

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setVeevaToken({ token }));
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search.slice(1));
      params.delete('token');
      const remove = process.env.REACT_APP_USE_REMOVE_TOKEN_FROM_URL === 'true';
      if (remove) {
        window.history.replaceState({}, '', `${window.location.pathname}?${params}${window.location.hash}`);
      }
      ([
        { key: 'key', value: keyValue },
        { key: 'recordNum', value: recordNum },
        { key: 'ccRecordNum', value: ccRecordNum },
        { key: 'PVSaRecordNum', value: PVSaRecordNum },
        { key: 'COVEWhereUsed', value: COVEWhereUsed },
        { key: 'COVEWhereFrom', value: COVEWhereFrom },
        { key: 'userName', value: userName },
        { key: 'userID', value: userID },
        { key: 'recordName', value: recordName },
      ] as const)
        .forEach((value) =>
          dispatch(setProperty(value)));
    }, []);

    if (!token) {
      return (
        <div>
          You have accessed this search tool from an incorrect location.
          {' '}
          Please access this search tool only from the authorized Veeva environment.
        </div>
      );
    }
    return (
      <div>
        {component()}
      </div>
    );
  };
}
