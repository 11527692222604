import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { getErrorMessage } from '../../entyties/batch/model/Batches';
import { selectCartErrors, selectCartSuccess } from '../../entyties/cart/model/Cart';
import { getVeevaObject } from '../../entyties/veevaObject/model/Object';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const Notification = React.memo(() => {
  const [api, contextHolder] = notification.useNotification();

  const errorMessage = useSelector(getErrorMessage);
  const cartErrors = useSelector(selectCartErrors);
  const cartSuccess = useSelector(selectCartSuccess);
  const { error: veevaError } = useSelector(getVeevaObject);
  useEffect(() => {
    if (cartSuccess.length) {
      api.success({
        message: 'Success - Batch Created',
      });
    }
  }, [cartSuccess]);

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: 'Error occurred',
      description: message,
      duration: null,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      openNotificationWithIcon('error', 'Your session has timed out, please login again');
    }, 1000 * 60 * 30);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      openNotificationWithIcon('error', errorMessage);
    }
    if (cartErrors.length) {
      cartErrors.forEach((error) => openNotificationWithIcon('error', error.message));
    }
    if (veevaError) {
      openNotificationWithIcon('error', veevaError);
    }
  }, [errorMessage, cartErrors, veevaError]);

  return (
    <div>
      { contextHolder }
    </div>
  );
});

export default Notification;
