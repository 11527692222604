import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RequireAtLeastOne<T> = {
    [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>
}[keyof T]

export const APP_TYPES = {
  MRLCC: 'MRLCC',
  SAFETY: 'SAFETY',
  COVEWhereUsed: 'COVEWhereUsed',
  COVEWhereFrom: 'COVEWhereFrom',
} as const;

type Key = keyof typeof APP_TYPES

type ConfigSliceState = {
    key?: Key,
    num?: string,
    recordNum?: string,
    recordName?: string,
} & RequireAtLeastOne<{
    ccRecordNum: string,
    PVSaRecordNum: string,
    complaintNum: string,
    COVEWhereUsed: string,
    COVEWhereFrom: string,
    userID: string,
    userName: string,
}>

const initialState: ConfigSliceState = {
  key: '' as Key,
  num: '',
  recordNum: '',
  recordName: '',
  ccRecordNum: '',
  PVSaRecordNum: '',
  complaintNum: '',
  COVEWhereUsed: '',
  COVEWhereFrom: '',
  userName: '',
  userID: '',
};

export const isKeyValues = (val?: string | Key): val is Key => {
  const arr = Object.keys(APP_TYPES);
  return (typeof val === 'string' && arr.includes(val));
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setProperty: (
      state,
      { payload }: PayloadAction<{key: keyof ConfigSliceState, value: string }>,
    ) => {
      const { key, value } = payload;
      // @ts-ignore
      state[key] = value;
    },
  },
});

export const selectNum = (state: RootState) => state.config.num;
export const selectRecordNum = (state: RootState) => state.config.recordNum;
export const selectCcRecordNum = (state: RootState) => state.config.ccRecordNum;
export const selectPVSaRecordNum = (state: RootState) => state.config.PVSaRecordNum;
export const selectComplaintNumber = (state: RootState) => state.config.complaintNum;
export const selectWhereUsedNumber = (state: RootState) => state.config.COVEWhereUsed;
export const selectWhereFromNumber = (state: RootState) => state.config.COVEWhereFrom;
export const selectKey = (state: RootState) => state.config.key;
export const selectRecordName = (state: RootState) => state.config.recordName;

export const selectFlowType = (state: RootState) => {
  const key = state?.config?.key;
  if (key && APP_TYPES[key]) return APP_TYPES[key];
  return null;
};

export const { setProperty } = configSlice.actions;
