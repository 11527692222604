import { createSlice } from '@reduxjs/toolkit';
import { veevaApi } from '../api/veevaObjectApi';

type ObjectStatus = 'idle' | 'pending' | 'succeeded' | 'failed'

type ObjectState = {
    recordName: string,
    batchId?: string
    error?: string
    status: ObjectStatus
}

const initialState: ObjectState = {
  recordName: '',
  batchId: '',
  error: '',
  status: 'idle',
};

export const veevaOjectSlice = createSlice({
  name: 'veevaObject',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      veevaApi.endpoints.object.matchFulfilled,
      (state, { payload }) => {
        // @ts-ignore
        state.recordName = payload?.recordName;
        // @ts-ignore
        state.batchId = payload?.batchId;
        state.status = 'succeeded';
      },
    );
    builder.addMatcher(
      veevaApi.endpoints.object.matchPending,
      (state) => {
        state.status = 'pending';
        state.error = '';
      },
    );
    builder.addMatcher(
      veevaApi.endpoints.object.matchRejected,
      (state) => {
        state.status = 'failed';
        state.error = 'Failed to load Veeva object details, re-open application';
      },
    );
  },
});

// @ts-ignore
export const getVeevaObject = (state: RootState) => state.veevaObject;
