export const ENDPOINTS = {
  GET_BATCHES: '/batches/batch/:num',
  GET_BATCHES_WHERE_FROM: '/batches/whereFrom/:num',
  GET_BATCHES_WHERE_USED: '/batches/whereUsed/:num',
  GET_AUTOCOMPLETE_BATCH: '/autocomplete/batch/:num',
  GET_AUTOCOMPLETE_WHERE_FROM: '/autocomplete/batch/:num',
  GET_AUTOCOMPLETE_WHERE_USED: '/autocomplete/batch/:num',
  GET_GENEALOGY_WHERE_FROM: '/batches/whereFrom/:num/genealogy',
  GET_GENEALOGY_WHERE_USED: '/batches/whereUsed/:num/genealogy',
  GET_GENEALOGY_WHERE_FROM_FILTERS: '/batches/whereFrom/:num/genealogy/filter/:filter',
  GET_GENEALOGY_FILTERS: '/batches/:flow/:num/genealogy/filter/:filter',
  GET_GENEALOGY_WHERE_USED_FILTERS: '/batches/whereUsed/:num/genealogy/filter/:filter',
  SAVE_BATCH: '/batches',
  SAVE_BATCH_WHERE_FROM: '/batches/whereFrom/genealogy',
  SAVE_BATCH_WHERE_USED: '/batches/whereUsed/genealogy',
  GET_VEEVA_OBJECT: '/veeva/:objectName/:recordNumber/',
} as const;

export const getUrl = (
  url: (typeof ENDPOINTS)[keyof typeof ENDPOINTS],
  mapping: Record<string, string>,
) => url
  .split('/')
  .map((part) => (part.startsWith(':') ? mapping[part.slice(1)] || '' : part))
  .join('/');
