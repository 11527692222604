import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  type Cart,
  type CartItem,
  VeevaResponse,
  VeevaResponsePayloadError,
  VeevaResponsePayloadSuccess,
  VeevaResponseStatus,
} from './types';
import type { Batch } from '../../batch/model/types';

export const veevaResponseStatusMap: Record<Lowercase<VeevaResponseStatus>, VeevaResponseStatus> = {
  success: 'SUCCESS',
  failure: 'FAILURE',
};

type CartSliceState = {
  isLoading: boolean,
  errors: VeevaResponsePayloadError[],
  success: VeevaResponsePayloadSuccess[]
} & Cart

const initialState: CartSliceState = {
  items: {},
  isLoading: false,
  errors: [],
  success: [],
};

function createCartItem(item: Batch): CartItem {
  return {
    ...item,
  };
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearSuccess: (state) => {
      state.success = [];
    },
    clearCart: (state) => {
      state.items = {};
      state.success = [];
      state.errors = [];
    },
    addErrors: (state, action:PayloadAction<VeevaResponse>) => {
      state.errors = (action.payload?.data || [])
        .filter((item) =>
          item.responseStatus === veevaResponseStatusMap.failure)
        .map((el) => el.data) as VeevaResponsePayloadError[];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addSuccess: (state, action: PayloadAction<VeevaResponse>) => {
      state.success = [1, 2, 3] as any;/* (action.payload?.data || [])
        .filter((item) =>
          item.responseStatus === veevaResponseStatusMap.success)
        .map((el) => el.data).concat([(1 as any)]) as VeevaResponsePayloadSuccess[]; */
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    addItem: (state, action: PayloadAction<Batch>) => {
      // batchId is not unique, if the item with the same id is added
      // the item in the cart would be overwritten!!!
      // in this way we guarantee that the item with the same batchId
      // would never be added to cart
      state.items[action.payload.id] = createCartItem(action.payload);
    },
    removeItem: (state, action: PayloadAction<Batch>) => {
      if (state.items[action.payload.id]) {
        // @ts-ignore
        state.items[action.payload.id] = undefined;
        delete state.items[action.payload.id];
      }
    },
  },
});

export const selectProductsInCart = (state: RootState) => state.cart.items;
export const isInTheCart = (batchId: Batch['batchId']) => (state: RootState) => !!state.cart.items[batchId];
export const isCartLoading = (state: RootState) => state.cart.isLoading;
export const selectCartErrors = (state: RootState) => state.cart.errors;
export const selectCartSuccess = (state: RootState) => state.cart.success;
export const getItemInCartByBatchId = (batchId: Batch['batchId']) =>
  (state: RootState) =>
    Object.values(state.cart.items).find((el) => el.batchId === batchId);

export const {
  addItem,
  removeItem,
  clearCart,
  addSuccess,
  setLoading,
  addErrors,
  clearSuccess,
} = cartSlice.actions;
