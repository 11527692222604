import React from 'react';
import Notification from '../features/notification/ui';
import { withProviders } from './providers';
import Routing from '../pages';
import Layout from '../widgets/layout/Layout';

function App() {
  return (
    <Layout>
      <Notification />
      <Routing />
    </Layout>
  );
}

export default withProviders(App);
