import { combineReducers } from '@reduxjs/toolkit';
import { baseAPI, veevaAPI } from '../shared/api/baseAPI';
import { configSlice } from '../entyties/config/model/Config';
import { sessionSlice } from '../entyties/veevaSession/model/VeevaSession';
import { cartSlice } from '../entyties/cart/model/Cart';
import { batchesSlice } from '../entyties/batch/model/Batches';
import { veevaOjectSlice } from '../entyties/veevaObject/model/Object';

export const rootReducer = combineReducers({
  [cartSlice.name]: cartSlice.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [configSlice.name]: configSlice.reducer,
  [batchesSlice.name]: batchesSlice.reducer,
  [baseAPI.reducerPath]: baseAPI.reducer,
  [veevaAPI.reducerPath]: veevaAPI.reducer,
  [veevaOjectSlice.name]: veevaOjectSlice.reducer,
});
