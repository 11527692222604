import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { veevaBaseQuery } from './veevaBaseQuery';

const SESSION_TAG = 'SESSION_TAG';
const VEEVA_TAG = 'VEEVA_TAG';
const BATCH_TAG = 'BATCH_TAG';

export const baseAPI = createApi({
  tagTypes: [BATCH_TAG],
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
});

export const veevaAPI = createApi({
  tagTypes: [SESSION_TAG, VEEVA_TAG],
  reducerPath: 'veeva/api',
  baseQuery: veevaBaseQuery,
  endpoints: () => ({}),
});
