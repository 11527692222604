import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './app/styles/index.css';
import { MDSConfigProvider } from '@mds/merck-design-system';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { appStore } from './app/store';

async function initApp() {
  if (process.env.REACT_APP_USE_MOCK_DATA === 'false') return;
  const module = await import('./app/mockAPI');
  await module.runMockServer();
}

export function Main() {
  return (
    <MDSConfigProvider theme="merckLightTheme">
      <ReduxProvider store={appStore}>
        <App />
      </ReduxProvider>
    </MDSConfigProvider>
  );
}

initApp().then(() => {
  const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement,
  );
  root.render(<Main />);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
